@import "../../_mixins.scss";

.realportfolio {
  color: #31cf61;
  color: $white;
  width: 100%;
  min-height: 100vh;
  &.blockScroll {
    max-height: 100vh;
    overflow: hidden;
  }
  p {
    font-size: 1em;
    font-weight: 300;
    margin: 0;
    color: colorOpacity($white, 0.6);
  }
  .grid {
    @include contentWidth();

    display: grid;
    grid-template-columns: 396px auto;
    gap: 20px;
    grid-template-areas: "a b";
    @include tablet {
      grid-template-columns: 1fr;
      grid-template-areas: "a" "b";
    }
    > * {
      padding: 10vh 0;
      @include tablet {
        padding: 5vh 0;
      }
    }
    .left {
      grid-area: a;
      display: flex;
      position: fixed;
      height: 100vh;
      box-sizing: border-box;
      width: 396px;
      flex-direction: column;
      text-align: left;
      justify-content: space-between;
      gap: 0.75em;
      @include tablet {
        position: relative;
        width: 100%;
        padding: 10vh 20px 20px;
        height: auto;
        gap: 3em;
      }
      .infos {
        h1 {
          line-height: 1.2;
        }
        h2 {
          margin-top: 0.75em;
        }
        p {
          margin-top: 1em;
        }
      }
      .nav {
        margin-top: 4em;
        @include tablet {
          display: none;
        }

        .unordered {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 1em;
          li {
            font-weight: 700;
            transition: 0.3s;
            opacity: 0.4;
            transition: all 0.1s ease-in-out;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            &:hover,
            &.active {
              opacity: 1;

              a::before {
                width: 64px;
              }
            }
            a {
              color: $white;
              text-decoration: none;
              font-size: 0.75em;
              cursor: pointer;

              display: flex;
              align-items: center;
              &::before {
                display: inline-block;
                content: "";
                height: 1px;
                width: 32px;
                background-color: $white;
                margin-right: 1em;
                transition: all 0.1s ease-in-out;
              }
            }
          }
        }
      }
    }
    .right {
      grid-area: b;
      display: flex;
      flex-direction: column;
      gap: 10vh;
      text-align: left;

      max-width: 560px;
      margin-left: auto;

      @include tablet {
        max-width: 100%;
        padding-bottom: 10vh;
      }
      .about {
        @extend .anchor;
        // padding-left: 1.25em;
      }
      .block {
        width: 100%;
        min-height: 100px;
        background-color: $white;
        opacity: 0.1;
      }
    }
  }
}
