@import "../../_mixins.scss";

.Socials {
  display: flex;
  align-items: center;
  gap: 2em;
  .social {
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    svg {
      width: 1.8em;
      height: 1.8em;
    }
    &:hover {
      opacity: 1;
    }
  }
}
