// Variables
$mobile-width: 500px;
$tablet-width: 900px;
$marge-mobile: 20px;
$marge-tablet: 40px;
$content-width: 1088px;

@mixin contentWidth($content-width: $content-width) {
  // Choisissez la largeur maximale que vous souhaitez pour votre contenu
  margin-left: auto;
  margin-right: auto;
  max-width: $content-width;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: calc(#{$content-width} + 2 * #{$marge-tablet})) {
    max-width: 100%; // Pour les tablettes, utilisez la largeur maximale disponible
    margin: 0 $marge-tablet; // Ajustez les marges minimales pour les tablettes
    width: auto;
  }

  @media screen and (max-width: calc(#{$mobile-width} + 2 * #{$marge-mobile})) {
    max-width: 100%; // Pour les smartphones, utilisez la largeur maximale disponible
    margin: 0 $marge-mobile; // Ajustez les marges minimales pour les smartphones
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-width) {
    @content;
  }
}

@function colorOpacity($color, $opacity) {
  @return rgba($color, $opacity);
}
.anchor {
  margin-top: -10vh;
  padding-top: 10vh;
}

//colors
$blue: #0f172a;
$secondary: #1bf3b6;
$white: #e2e8f0;
