@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "./mixins.scss";

html {
  scroll-behavior: smooth;
  /* width */
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    // background-color: #071221;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $white;
    opacity: 0.5;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
