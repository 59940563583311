@import "../../_mixins.scss";

.List {
  display: flex;
  gap: 1.25em;
  flex-direction: column;
  @extend .anchor;
  > h2 {
    // padding-left: 20px;
  }
  > a {
    text-decoration: none;
    color: $white;
    // padding-left: 20px;
  }
}
