@import "../../_mixins.scss";

.Anchor {
  color: $white;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-weight: 500;
  .arrowContainer {
    margin-left: 0.2em;
    display: inline;
    position: relative;
    .arrow {
      position: absolute;
      bottom: 0.02em;
      left: 0.04em;
      transition: all 0.2s ease-in-out;
      path {
        stroke: $white;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &.forceHover,
  &:hover {
    color: $secondary;
    .arrow {
      bottom: 0.15em;
      left: 0.15em;
      path {
        stroke: $secondary;
      }
    }
  }
}
