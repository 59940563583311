@import "../../_mixins.scss";
.contact {
  display: flex;
  flex-direction: column;
  @extend .anchor;
  > p {
    margin-top: 0.8em;
  }
  .form {
    display: flex;
    margin-top: 1.8em;
    flex-direction: column;
    gap: 1.5em;
    > * {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      opacity: 1;
      background-color: colorOpacity($white, 0.05);
      border: none;
      border-radius: 0.5em;
      box-sizing: border-box;
      border-top: solid 1px #ffffff24;
      margin: 0;
      color: $white;
      font-size: 1em;
    }
    input,
    textarea {
      padding: 1em;
      min-width: 100%;
      max-width: 100%;
      max-height: 400px;
      &::placeholder {
        color: colorOpacity($white, 0.7);
      }
      &::-ms-input-placeholder {
        color: colorOpacity($white, 0.7);
      }
    }
    textarea {
      min-height: 200px;
    }
    .button {
      background-color: colorOpacity($secondary, 0.1);
      color: $secondary;
      font-size: 1em;
      padding: 1em 2em;
      border-radius: 0.5em;
      border: none;
      cursor: pointer;
      transition: all 0.3s;
      width: fit-content;
      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
      &:hover {
        background-color: colorOpacity($secondary, 0.2);
      }
    }
  }
}
