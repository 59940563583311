@import "../../_mixins.scss";

.AnimatedText {
  // text-shadow: 0 0 2px #31cf61;
  margin: 0 auto;

  > * {
    margin: 0;
    width: fit-content;
    position: relative;
  }
  &.center {
    margin: auto;
  }
  &.left {
    margin: 0;
  }
  &.right {
    margin-left: auto;
  }

  .text {
    &.visible {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: left;
      .cursor {
        animation: blink 1s infinite;
      }
    }
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@keyframes blink {
  0%,
  50% {
    color: inherit;
  }

  51%,
  99% {
    color: transparent;
  }
  100% {
    color: inherit;
  }
}
