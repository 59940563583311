@import "../../_mixins.scss";

.landing {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #071221;
  height: 100%;
  width: 100%;
  background-color: $white;
  flex: 1;
  justify-content: center;
  gap: 2em;
  > * {
    margin: 0;
  }
  opacity: 1;
  &.started {
    cursor: default;
    opacity: 0;
    display: none;
    animation: disappearAll 2s;
    -webkit-animation: disappearAll 2s;
  }
  .name {
    padding: 1.25em;

    @include tablet {
      font-size: 2em;
    }
    .space {
      width: 26.36px;
      display: inline-block;
    }
    .letter {
      position: relative;
      display: inline-block;
      --angle: 0deg;
      &.started {
        transform: translate(var(--x), var(--y)) rotate(var(--rotation))
          scale(0.8);
        -webkit-transform: translate(var(--x), var(--y)) rotate(var(--rotation))
          scale(0.8);
        animation: explode 2s ease-out;
        -webkit-animation: explode 2s ease-out;

        .circle {
          display: none;
          width: 150vw;

          animation: grow 2s;
        }
      }
      .circle {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: #071221;
        border-radius: 50%;
        width: 0px;
        aspect-ratio: 1/1;
        transition: width 0.3s;
      }
    }
  }
  .job {
    font-size: 0.75em;
    font-weight: 200;
    letter-spacing: 0.6em;
    padding: 1.25em;
    &.started {
      opacity: 0;
      animation: disappear 1s;
      -webkit-animation: disappear 1s;
    }
  }
  .button {
    position: absolute;
    bottom: 10vh;
    right: 10vh;
    padding: 0.5em;
    border-radius: 3px;
    border: none;
    font-size: 0.9em;
    font-weight: 400;
    letter-spacing: 4px;

    @include tablet {
      font-size: 0.7em;
      bottom: 5vh;
      right: 50%;
      transform: translate(50%, 0);
    }
    &.started {
      opacity: 0;
      animation: disappear 1s;
      -webkit-animation: disappear 1s;
    }
  }
}

@keyframes explode {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  100% {
    transform: translate(var(--x), var(--y)) rotate(var(--rotation)) scale(0.8);
  }
}

@keyframes grow {
  0%,
  60% {
    width: 0px;
  }
  95% {
    width: 150vw;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes disappearAll {
  0%,
  50% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: flex;
  }
}

@-webkit-keyframes explode {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg) scale(1);
  }
  100% {
    -webkit-transform: translate(var(--x), var(--y)) rotate(var(--rotation))
      scale(0.8);
  }
}

@-webkit-keyframes grow {
  0%,
  60% {
    width: 0px;
  }
  95% {
    width: 150vw;
  }
}

@-webkit-keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes disappearAll {
  0%,
  50% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: flex;
  }
}
