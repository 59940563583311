@import "./mixins.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #071221;
  min-height: 100vh;
  font-size: 16px;
  line-height: 1.625;
  h1 {
    font-size: 3.5em;
    font-weight: 700;
    margin: 0;
  }
  h2 {
    font-size: 1.25em;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    margin: 0;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes liner {
  50% {
    --lines: 0.00012px;
  }
}
