@import "../../../_mixins.scss";

.ListElement {
  cursor: pointer;
  display: flex;
  gap: 0.8em;
  display: grid;
  grid-template-columns: 100px 3fr;
  opacity: 1;
  padding: 1.2em;
  transition: all 0.5s ease;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  border-radius: 0.5em;
  border-top: solid 1px transparent;

  color: $white;
  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  &.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-top: solid 1px #ffffff24;
    opacity: 1;
    background-color: colorOpacity($white, 0.05);
    .right {
      h2 {
        color: $secondary;
        path {
          stroke: $secondary;
        }
      }
    }
  }
  &.inactive {
    opacity: 0.35;
  }
  .left {
    display: flex;
    flex-direction: column;
    .leftText {
      font-size: 0.75em;
      font-weight: 500;
      opacity: 0.5;
      margin-bottom: 0.8em;
      display: block;
      width: max-content;
      @include mobile {
        display: none;
      }
    }
    img {
      width: 100%;
      box-sizing: border-box;
      border-radius: 10px;
      border: solid 3px colorOpacity($white, 0.1);
      @include mobile {
        width: 100px;
      }
    }
  }

  &.logo {
    .left {
      img {
        width: 80%;
        @include mobile {
          width: 100px;
        }
      }
    }
  }
  .right {
    .leftTextMobile {
      display: none;
      @include mobile {
        display: inline;
        font-size: 0.75em;
        font-weight: 500;
        opacity: 0.5;
        margin-bottom: 0.5em;
      }
    }
    h2 {
      font-weight: 500;
      font-size: 1em;
      path {
        stroke: $white;
        transition: all 0.3s ease-in-out;
      }
    }
    h3 {
      font-size: 1em;
      font-weight: 500;
      opacity: 0.3;
    }
    p {
      margin-top: 0.7em;
      font-size: 0.8em;
      opacity: 0.9;
    }

    .tags {
      display: flex;
      gap: 1em;
      flex-wrap: wrap;
      margin-top: 0.7em;
      text-transform: capitalize;
      span {
        padding: 0.4em 1em;
        border-radius: 0.5em;
        background-color: colorOpacity($secondary, 0.1);
        color: $secondary;
        font-size: 0.7em;
      }
    }
  }
}
